import React, { useEffect } from "react";
import {
  Panel,
  Swatch,
  HStack,
  VStack,
  Loader,
  NoData,
  ComponentSwitch,
} from "@pp/lib/ui/components";
import WidgetPanel from "../../shared/WidgetPanel";

import Icons from "@pp/lib/ui/app/Icons";
import {
  VenueContext,
  TimeRangeContext,
  AccountContext,
  BenchmarkContext,
} from "@pp/ui/app/GlobalContexts";
import api from "@pp/lib/net/api-service";
import LineGraphByMonth from "../../shared/LineGraphByMonth";
import ScoreBarChart from "../../shared/ScoreBarChart";
import { previousYearRange } from "@pp/lib/utils";
import GraphKey from "../../shared/GraphKey";
import colours from "@pp/lib/ui/theme/colours";
import Tooltips from "@pp/ui/widgets/shared/tooltips";
import { yearAndMonth } from "@pp/lib/utils";
import Analytics from "@pp/utils/analytics";
import { DEFAULT_PERCENTAGE_SCALE, fillMissingMonths } from "@pp/utils/data";

const processScore = (
  currentData,
  previousData,
  themeName,
  benchmarkData,
  currentYearRange,
  previousYearRange
) => {
  let delta = 0;
  let change = 0;
  let score = 0;

  if (currentData.length === 0) {
    return { score, delta, change, data: { current: [], previous: [] } };
  }

  score = currentData[currentData.length - 1].Score;
  if (currentData.length >= 2) {
    let prev = currentData[currentData.length - 2].Score;
    if (previousData?.length === 12) {
      prev = previousData[previousData.length - 1].Score;
    }
    delta = Number(score.toFixed()) - Number(prev.toFixed());
    change = delta / DEFAULT_PERCENTAGE_SCALE;
  }

  const current = currentData.map(({ Score: value, Date: d }) => {
    return {
      date: new Date(d),
      value,
    };
  });

  // Find the benchmarks for each of the data points by matching the dates.
  const benchmarks = current.map(({ date }: { date: Date }) => {
    const dateString = yearAndMonth(date);
    const benchmarkSet = benchmarkData.find((b) => b.date === dateString);
    if (!benchmarkSet || !benchmarkSet.data) {
      return null;
    }
    const benchmark = benchmarkSet.data[themeName.toLowerCase()];
    if (!benchmark) {
      return null;
    }
    return {
      date,
      value: benchmark.score,
    };
  });

  let previous = [];
  if (previousData) {
    previous = previousData.map(({ Score: value, Date: d }) => {
      return {
        value,
        date: new Date(d),
      };
    });
  }

  // Sometimes the previous data won't go back as far as the current data.
  // So we need to add empty data points to the start of the previous data so that the lines on the graph are aligneds.
  // while (previous.length < current.length) {
  //   previous.unshift({});
  // }

  let filledPreviousYearData = [];
  let filledCurrentYearData = [];
  let filledBenchmarksYearData = [];
  if (current?.length && currentYearRange) {
    filledCurrentYearData = fillMissingMonths(currentYearRange, current);
  }
  if (previous?.length && previousYearRange) {
    filledPreviousYearData = fillMissingMonths(previousYearRange, previous);
  }
  if (benchmarks?.length && currentYearRange) {
    filledBenchmarksYearData = fillMissingMonths(currentYearRange, benchmarks);
  }

  return {
    score,
    delta,
    change,
    data: {
      current: filledCurrentYearData?.length ? filledCurrentYearData : current,
      previous: filledPreviousYearData?.length
        ? filledPreviousYearData
        : previous,
    },
    benchmarks: filledBenchmarksYearData?.length
      ? filledBenchmarksYearData
      : benchmarks,
  };
};

const LINE_CHART = "line";
const BAR_CHART = "donut";

const ThemesOverview = () => {
  const { account } = React.useContext(AccountContext);
  const { venue } = React.useContext(VenueContext);
  const { benchmark } = React.useContext(BenchmarkContext);
  const { timeRange } = React.useContext(TimeRangeContext);
  const [themes, setThemes] = React.useState(null);
  const [activeView, setActiveView] = React.useState(LINE_CHART);
  const trackWidgetToggle = Analytics.useWidgetToggle("experience_themes");

  useEffect(() => {
    const makeCall = async () => {
      const prevRange = previousYearRange(timeRange);
      const promises = [
        api.themes({
          accountId: account.shortId,
          venueId: venue.id,
          benchmark: benchmark?.id,
          ...timeRange,
        }),
        api.themes({
          accountId: account.shortId,
          venueId: venue.id,
          ...prevRange,
        }),
      ];

      const result = await Promise.all(promises);
      const current = result[0];
      const previous = result[1];

      const tms = current.Themes.map(({ ThemeName, DataPoints }) => {
        let prev;
        if (previous) {
          prev = previous.Themes.find((tp) => tp.ThemeName === ThemeName);
        }

        return {
          id: ThemeName,
          label: ThemeName,
          ...processScore(
            DataPoints,
            prev?.DataPoints,
            ThemeName,
            current.BenchmarkData,
            timeRange,
            prevRange
          ),
        };
      });
      setThemes(tms);
    };
    setThemes(null);
    makeCall();
  }, [venue, timeRange, benchmark]);

  const setTheActiveView = (viewType) => {
    setActiveView(viewType);
    trackWidgetToggle(viewType);
  };

  return (
    <WidgetPanel
      title="Experience Themes"
      tooltip={Tooltips.EXPERIENCE_THEMES}
      tooltipAnalyticEvent={"experience_themes"}
      actions={
        <Panel.Actions>
          <Panel.ActionButton
            onClick={() => setTheActiveView(LINE_CHART)}
            icon={Icons.LineGraph}
          />
          <Panel.ActionButton
            onClick={() => setTheActiveView(BAR_CHART)}
            icon={Icons.BarGraphHorizontal}
          />
        </Panel.Actions>
      }
    >
      <Loader
        css={{ padding: "30px" }}
        data={themes}
        content={() => {
          return (
            <ComponentSwitch
              tests={[
                () =>
                  activeView === LINE_CHART && <LineChart themes={themes} />,
                () =>
                  activeView === BAR_CHART && (
                    <ScoreBarChart
                      keyLabel="Theme Score"
                      scores={themes}
                      tooltipFormatter={ScoreBarChart.tooltipHelper()}
                    />
                  ),
              ]}
            />
          );
        }}
      />
    </WidgetPanel>
  );
};

const LineChart = ({ themes }) => {
  const firstTheme = themes && themes[0];
  const [activeTheme, setActiveTheme] = React.useState(firstTheme);
  const trackWidgetTab = Analytics.useWidgetTab("experience_themes");

  return (
    <VStack css={{ flex: "1" }}>
      <ThemeMenu
        themes={themes}
        active={activeTheme}
        onSelect={(theme) => {
          setActiveTheme(theme);
          trackWidgetTab(theme.id);
        }}
      />
      {activeTheme &&
      activeTheme.data &&
      activeTheme.data.current &&
      activeTheme.data.current.length > 0 ? (
        <Graph theme={activeTheme} />
      ) : (
        <NoData />
      )}
    </VStack>
  );
};

const Graph = ({ theme }) => {
  const { benchmark } = React.useContext<{ benchmark? }>(BenchmarkContext);

  return (
    <>
      <LineGraphByMonth
        data={theme.data}
        benchmark={theme.benchmarks}
        tooltipFormatter={LineGraphByMonth.tooltipHelper(
          `${theme.label} Score`,
          benchmark?.label
        )}
        // percentiles={LineGraphByMonth.percentileHelper(-50, 25, 75, 100)}
      />

      <HStack css={{ ml: "$4", mt: "$4", gap: "$3" }}>
        <GraphKey.Circle color={colours.ppForest} label="Theme Score" />
        {theme?.data.previous.length > 0 && (
          <GraphKey.Circle color={colours.greys.grey1} label="Previous Year" />
        )}
        {benchmark && theme.benchmarks.length > 0 && (
          <GraphKey.Circle
            color={colours.greys.grey3}
            label={benchmark.label}
          />
        )}
      </HStack>
    </>
  );
};

const ThemeMenu = ({ themes, active, onSelect }) => {
  return (
    <Swatch.HSet css={{ flex: "1" }}>
      {themes.map((theme) => {
        const { id, label, score, change } = theme;
        return (
          <React.Fragment key={id}>
            <Swatch.ScoreSwatch
              title={label}
              score={score.toFixed(1)}
              change={change}
              active={active === theme}
              onClick={() => {
                onSelect(theme);
              }}
            />
          </React.Fragment>
        );
      })}
    </Swatch.HSet>
  );
};

export default ThemesOverview;
