import React, { useEffect } from "react";

import {
  Panel,
  Swatch,
  HStack,
  Loader,
  ComponentSwitch,
  Link,
} from "@pp/lib/ui/components";
import WidgetPanel from "../../shared/WidgetPanel";
import { processTouchpoints } from "../../../../utils/data";
import Icons from "@pp/lib/ui/app/Icons";
import {
  AccountContext,
  VenueContext,
  BenchmarkContext,
  TimeRangeContext,
} from "@pp/ui/app/GlobalContexts";
import api from "@pp/lib/net/api-service";
import ScoreBarChart from "../../shared/ScoreBarChart";
import { previousYearRange } from "@pp/lib/utils";
import TouchpointLineGraph from "@pp/ui/widgets/touchpoints/TouchpointLineGraph";
import TouchpointStackedBarGraph from "@pp/ui/widgets/touchpoints/TouchpointStackedBarGraph";
import Tooltips from "@pp/ui/widgets/shared/tooltips";
import Analytics from "@pp/utils/analytics";

const LINE_CHART = "line";
const BAR_CHART = "bar";
const DISTRIBUTION_BAR = "distribution";

const TouchpointsOverview = () => {
  const { account } = React.useContext(AccountContext);
  const { venue } = React.useContext(VenueContext);
  const { benchmark } = React.useContext(BenchmarkContext);
  const { timeRange } = React.useContext(TimeRangeContext);
  const [activeView, setActiveView] = React.useState(LINE_CHART);
  const [touchpoints, setTouchpoints] = React.useState(null);
  const trackWidgetToggle = Analytics.useWidgetToggle("touchpoints_overview");

  useEffect(() => {
    const makeCall = async () => {
      const prevRange = previousYearRange(timeRange);
      const promises = [
        api.touchpoints({
          accountId: account.shortId,
          venueId: venue.id,
          benchmark: benchmark?.id,
          ...timeRange,
        }),
        api.touchpoints({
          accountId: account.shortId,
          venueId: venue.id,
          ...prevRange,
        }),
      ];
      const result = await Promise.all(promises);
      const tps = processTouchpoints(
        result[0],
        result[1],
        timeRange,
        prevRange
      );
      setTouchpoints(tps);
    };
    setTouchpoints(null);
    makeCall();
  }, [venue, timeRange, benchmark]);

  const setTheActiveView = (viewType) => {
    setActiveView(viewType);
    trackWidgetToggle(viewType);
  };

  return (
    <WidgetPanel
      title="Touchpoints Overview"
      tooltip={Tooltips.TOUCHPOINTS_OVERVIEW}
      tooltipAnalyticEvent={"touchpoints_overview"}
      actions={
        <Panel.Actions>
          <Panel.ActionButton
            onClick={() => setTheActiveView(LINE_CHART)}
            icon={Icons.LineGraph}
          />
          <Panel.ActionButton
            onClick={() => setTheActiveView(DISTRIBUTION_BAR)}
            icon={Icons.StackedBarGraph}
          />
          <Panel.ActionButton
            onClick={() => setTheActiveView(BAR_CHART)}
            icon={Icons.BarGraphHorizontal}
          />
        </Panel.Actions>
      }
    >
      <Loader
        css={{ padding: "30px", boxSizing: "border-box" }}
        data={touchpoints}
        content={() => {
          return (
            <ComponentSwitch
              tests={[
                () =>
                  (activeView === LINE_CHART ||
                    activeView === DISTRIBUTION_BAR) && (
                    <TouchpointMenuAndCharts
                      activeView={activeView}
                      touchpoints={touchpoints}
                    />
                  ),
                () =>
                  activeView === BAR_CHART && (
                    <ScoreBarChart
                      keyLabel="Touchpoint Score"
                      scores={touchpoints}
                      tooltipFormatter={ScoreBarChart.tooltipHelper()}
                      footerLink={<FooterLink />}
                    />
                  ),
              ]}
            />
          );
        }}
      />
    </WidgetPanel>
  );
};

const TouchpointMenuAndCharts = ({ activeView, touchpoints }) => {
  const firstTouchPoint = touchpoints && touchpoints[0];
  const [activeTouchpoint, setActiveTouchpoint] =
    React.useState(firstTouchPoint);
  const trackWidgetTab = Analytics.useWidgetTab("touchpoints_overview");

  if (!activeTouchpoint) return <></>;
  return (
    <HStack css={{ gap: "$4", flex: "1" }}>
      <TouchpointMenu
        touchpoints={touchpoints}
        active={activeTouchpoint}
        onSelect={(touchpoint) => {
          setActiveTouchpoint(touchpoint);
          trackWidgetTab(touchpoint.idName);
        }}
      />

      <ComponentSwitch
        tests={[
          () =>
            activeView === LINE_CHART && (
              <TouchpointLineGraph
                touchpoint={activeTouchpoint}
                footerLink={<FooterLink touchpoint={activeTouchpoint} />}
              />
            ),
          () =>
            activeView === DISTRIBUTION_BAR && (
              <TouchpointStackedBarGraph
                touchpoint={activeTouchpoint}
                footerLink={<FooterLink touchpoint={activeTouchpoint} />}
              />
            ),
        ]}
      />
    </HStack>
  );
};

const TouchpointMenu = ({ touchpoints, active, onSelect }) => {
  return (
    <Swatch.VSet css={{ width: "35%", height: "100%", overflowY: "auto" }}>
      {touchpoints.map((tp) => {
        const { id, label, score, change } = tp;
        return (
          <React.Fragment key={id}>
            <Swatch.ScoreSwatch
              title={label}
              score={score.toFixed(1)}
              active={active === tp}
              change={change}
              onClick={() => {
                onSelect(tp);
              }}
            />
          </React.Fragment>
        );
      })}
    </Swatch.VSet>
  );
};

const FooterLink = ({ touchpoint = null }) => {
  const trackLink = Analytics.useTrackLink();
  const link =
    touchpoint === null
      ? "/touchpoints"
      : `/touchpoints?touchpoint=${touchpoint.id}`;

  return (
    <Link
      to={link}
      onClick={() => {
        trackLink("view_complete_touchpoint");
      }}
    >
      View Touchpoint Detail →
    </Link>
  );
};

export default TouchpointsOverview;
