import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  MarkAreaComponent,
} from "echarts/components";

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent,
  MarkAreaComponent,
]);

import React, { useEffect } from "react";
import {
  Panel,
  VStack,
  Loader,
  Swatch,
  NoData,
  Text,
  Box,
  HStack,
  ChartWrapper,
  ComponentSwitch,
} from "@pp/lib/ui/components";
import WidgetPanel from "../../shared/WidgetPanel";
import {
  AccountContext,
  VenueContext,
  BenchmarkContext,
  TimeRangeContext,
} from "@pp/ui/app/GlobalContexts";
import api from "@pp/lib/net/api-service";
import LineGraphByMonth from "../../shared/LineGraphByMonth";
import Icons from "@pp/lib/ui/app/Icons";
import GraphKey from "../../shared/GraphKey";
import colours from "@pp/lib/ui/theme/colours";
import { previousYearRange, yearAndMonth } from "@pp/lib/utils";
import Tooltips from "@pp/ui/widgets/shared/tooltips";
import Analytics from "@pp/utils/analytics";
import { DEFAULT_PERCENTAGE_SCALE, fillMissingMonths } from "@pp/utils/data";

const processScore = (
  dataPoints,
  prevDataPoints,
  benchmarkData,
  benchmarkKey,
  currentYearRange,
  previousYearRange
) => {
  let delta = 0;
  let change = 0;
  let score = 0;

  if (dataPoints.length >= 1) {
    score = dataPoints[dataPoints.length - 1].Value;
    if (isNaN(score)) {
      score = 0;
    }
    if (dataPoints.length >= 2) {
      let prev = dataPoints[dataPoints.length - 2].Value;
      if (prevDataPoints?.length === 12) {
        prev = prevDataPoints[prevDataPoints.length - 1].Value;
      }
      if (!isNaN(prev) && prev !== 0) {
        delta = Number(score.toFixed()) - Number(prev.toFixed());
        change = delta / 200; // nps scale will be from -100 to +100
      }
    }
  }

  const currentData = dataPoints.map(({ Value, Date: d }, i) => {
    const value = parseFloat(Value);
    return {
      date: new Date(d),
      value,
    };
  });
  const previousData =
    prevDataPoints?.map(({ Value, Date: d }, i) => {
      const value = parseFloat(Value);
      return {
        date: new Date(d),
        value,
      };
    }) || [];

  // Find the benchmarks for each of the data points by matching the dates.
  const benchmarks = currentData.map(({ date }: { date: Date }) => {
    const dateString = yearAndMonth(date);
    const benchmark = benchmarkData.find((b) => b.date === dateString);

    const scores = benchmark?.data?.scores;
    if (!scores) {
      return null;
    }
    return {
      date,
      value: scores[benchmarkKey]?.score,
    };
  });
  // Sometimes the previous data won't go back as far as the current data.
  // So we need to add empty data points to the start of the previous data so that the lines on the graph are aligneds.
  // while (previousData.length < currentData.length) {
  //   previousData.unshift({});
  // }
  let filledPreviousYearData = [];
  let filledCurrentYearData = [];
  let filledBenchmarksYearData = [];
  if (currentData?.length && currentYearRange) {
    filledCurrentYearData = fillMissingMonths(currentYearRange, currentData);
  }
  if (previousData?.length && previousYearRange) {
    filledPreviousYearData = fillMissingMonths(previousYearRange, previousData);
  }
  if (benchmarks?.length && currentYearRange) {
    filledBenchmarksYearData = fillMissingMonths(currentYearRange, benchmarks);
  }

  return {
    delta,
    change,
    score,
    data: {
      current: filledCurrentYearData?.length
        ? filledCurrentYearData
        : currentData,
      previous: filledPreviousYearData?.length
        ? filledPreviousYearData
        : previousData,
    },
    benchmark: {
      data: filledBenchmarksYearData?.length
        ? filledBenchmarksYearData
        : benchmarks,
    },
  };
};

const LINE_CHART = "line";
const DONUT_CHART = "donut";

const NPSSummary = () => {
  const { account } = React.useContext<{ account? }>(AccountContext);
  const { venue } = React.useContext<{ venue? }>(VenueContext);
  const { benchmark } = React.useContext<{ benchmark? }>(BenchmarkContext);
  const { timeRange } = React.useContext<{ timeRange? }>(TimeRangeContext);

  const [fullData, setFullData] = React.useState(null);
  const [donutData, setDonutData] = React.useState(null);

  const [activeView, setActiveView] = React.useState(LINE_CHART);
  const trackWidgetToggle = Analytics.useWidgetToggle("nps_trend");

  useEffect(() => {
    const makeCall = async () => {
      const promises = [
        api.nps({
          accountId: account.shortId,
          venueId: venue.id,
          benchmark: benchmark?.id,
          ...timeRange,
        }),
        api.nps({
          accountId: account.shortId,
          venueId: venue.id,
          ...previousYearRange(timeRange),
        }),
      ];

      const res = await Promise.all(promises);
      const result = res[0];
      const previousResult = res[1];
      const prevRange = previousYearRange(timeRange);

      const npsScore = processScore(
        result.OverallScore.DataPoints,
        previousResult?.OverallScore?.DataPoints,
        result.BenchmarkData,
        "overall",
        timeRange,
        prevRange
      );

      const data = [
        {
          label: "NPS+ Score",
          ...npsScore,
        },
        {
          label: "Satisfaction",
          ...processScore(
            result.SatisfactionScore.DataPoints,
            previousResult?.SatisfactionScore?.DataPoints,
            result.BenchmarkData,
            "satisfaction",
            timeRange,
            prevRange
          ),
        },
        {
          label: "Fulfilment",
          ...processScore(
            result.ExceedScore.DataPoints,
            previousResult?.ExceedScore?.DataPoints,
            result.BenchmarkData,
            "exceed",
            timeRange,
            prevRange
          ),
        },
        {
          label: "Loyalty",
          ...processScore(
            result.LoyaltyScore.DataPoints,
            previousResult?.LoyaltyScore?.DataPoints,
            result.BenchmarkData,
            "loyalty",
            timeRange,
            prevRange
          ),
        },
        {
          label: "Advocacy",
          ...processScore(
            result.AdvocacyScore.DataPoints,
            previousResult?.AdvocacyScore?.DataPoints,
            result.BenchmarkData,
            "advocacy",
            timeRange,
            prevRange
          ),
        },
        {
          label: "Preference",
          ...processScore(
            result.CompetitionScore.DataPoints,
            previousResult?.CompetitionScore?.DataPoints,
            result.BenchmarkData,
            "competition",
            timeRange,
            prevRange
          ),
        },
      ];

      setFullData(data);

      const donut = {
        npsScore,
        promoters: processScore(
          result.OverallPromoters.DataPoints,
          previousResult?.OverallPromoters?.DataPoints,
          result.BenchmarkData,
          "overall",
          timeRange,
          prevRange
        ),
        passives: processScore(
          result.OverallPassives.DataPoints,
          previousResult?.OverallPassives?.DataPoints,
          result.BenchmarkData,
          "overall",
          timeRange,
          prevRange
        ),
        detractors: processScore(
          result.OverallDetractors.DataPoints,
          previousResult?.OverallDetractors?.DataPoints,
          result.BenchmarkData,
          "overall",
          timeRange,
          prevRange
        ),
      };
      setDonutData(donut);
    };
    setFullData(null);
    setDonutData(null);
    makeCall();
  }, [venue, benchmark, timeRange]);

  const setTheActiveView = (viewType) => {
    setActiveView(viewType);
    trackWidgetToggle(viewType);
  };

  return (
    <WidgetPanel
      title="NPS+ Trend"
      tooltip={Tooltips.NPS_PLUS_TREND}
      tooltipAnalyticEvent={"nps_trend"}
      actions={
        <Panel.Actions>
          <Panel.ActionButton
            onClick={() => setTheActiveView(LINE_CHART)}
            icon={Icons.LineGraph}
          />
          <Panel.ActionButton
            onClick={() => setTheActiveView(DONUT_CHART)}
            icon={Icons.Donut}
          />
        </Panel.Actions>
      }
    >
      <Loader
        css={{ padding: "30px", boxSizing: "border-box" }}
        data={fullData}
        content={() => {
          return (
            <ComponentSwitch
              tests={[
                () =>
                  activeView === LINE_CHART && (
                    <LineChart fullData={fullData} />
                  ),
                () =>
                  activeView === DONUT_CHART && <DonutChart data={donutData} />,
              ]}
            />
          );
        }}
      />
    </WidgetPanel>
  );
};

const percentileColours = [
  colours.teals.teal3,
  colours.teals.teal1,
  colours.teals.teal0,
];

const LineChart = ({ fullData }) => {
  const [graphData, setGraphData] = React.useState(fullData[0]);
  const { benchmark } = React.useContext<{ benchmark? }>(BenchmarkContext);
  const trackWidgetTab = Analytics.useWidgetTab("nps_trend");

  return (
    <VStack data-test="nps-trend-line-chart" css={{ flex: "1" }}>
      <Swatch.HSet css={{ flex: "1" }}>
        {fullData.map((fData, i) => {
          const { label, score, change, data } = fData;

          const titleCSS = i === 0 ? { fontWeight: "bold" } : {};

          return (
            <Swatch.ScoreSwatch
              key={label}
              active={graphData === fData}
              onClick={() => {
                setGraphData(fData);
                trackWidgetTab(label);
              }}
              title={label}
              titleCSS={titleCSS}
              score={score}
              change={change}
              formatter={(score) => {
                if (i === 0) {
                  return score > 0 ? `+${score}` : `${score}`;
                }
                return score;
              }}
            />
          );
        })}
      </Swatch.HSet>
      {graphData.data.current.length === 0 ? (
        <NoData />
      ) : (
        <>
          <LineGraphByMonth
            data={graphData.data}
            benchmark={graphData.benchmark.data}
            tooltipFormatter={LineGraphByMonth.tooltipHelper(
              "NPS+ Score",
              benchmark?.label
            )}
          />
          <HStack css={{ ml: "$4", mt: "$4", gap: "$3" }}>
            <GraphKey.Circle color={colours.ppForest} label="NPS+ Score" />
            {graphData.data.previous.length > 0 && (
              <GraphKey.Circle
                color={colours.greys.grey1}
                label="Previous Year"
              />
            )}
            {benchmark && graphData.benchmark.data.length > 0 && (
              <GraphKey.Circle
                color={colours.greys.grey3}
                label={benchmark.label}
              />
            )}
          </HStack>
        </>
      )}
    </VStack>
  );
};

const DonutChart = ({ data }) => {
  const { promoters, passives, detractors } = data;
  // Calculate totals and percentages
  const currentTotal = promoters.score + passives.score + detractors.score;
  const prevTotal =
    promoters.data.current.at(-2)?.value +
    passives.data.current.at(-2)?.value +
    detractors.data.current.at(-2)?.value;

  const calculatePercentage = (value, total) =>
    total > 0 ? Math.round((value / total) * 100) : 0;

  const currentPercentages = {
    promoters: calculatePercentage(promoters.score, currentTotal),
    passives: calculatePercentage(passives.score, currentTotal),
    detractors: calculatePercentage(detractors.score, currentTotal),
  };

  const prevPercentages = {
    promoters: calculatePercentage(
      promoters.data.current.at(-2)?.value,
      prevTotal
    ),
    passives: calculatePercentage(
      passives.data.current.at(-2)?.value,
      prevTotal
    ),
    detractors: calculatePercentage(
      detractors.data.current.at(-2)?.value,
      prevTotal
    ),
  };

  const changes = {
    promoters: (currentPercentages.promoters - prevPercentages.promoters) / 100,
    passives: (currentPercentages.passives - prevPercentages.passives) / 100,
    detractors:
      (currentPercentages.detractors - prevPercentages.detractors) / 100,
  };

  const npsScore = data.npsScore.score.toFixed(0);

  const npsPrefix = npsScore < 0 ? "-" : npsScore > 0 ? "+" : "";

  const option = {
    series: [
      {
        type: "pie",
        radius: ["65%", "90%"],
        label: {
          show: false,
        },
        data: [
          {
            value: data.promoters.score,
            name: "Promoters",
            itemStyle: {
              color: colours.teals.teal4,
            },
          },
          {
            value: data.passives.score,
            name: "Passives",
            itemStyle: {
              color: colours.greys.grey1,
            },
          },
          {
            value: data.detractors.score,
            name: "Detractors",
            itemStyle: {
              color: colours.ppForest,
            },
          },
        ],
      },
    ],
  };

  return (
    <HStack
      css={{
        flex: "1",
      }}
    >
      <VStack css={{ gap: "$6", flex: "1" }}>
        <DonutLabel
          label="Promoters"
          score={currentPercentages?.promoters}
          change={changes?.promoters}
          color={colours.teals.teal4}
        />
        <DonutLabel
          label="Passives"
          score={currentPercentages?.passives}
          change={changes?.passives}
          color={colours.greys.grey1}
        />
        <DonutLabel
          label="Detractors"
          score={currentPercentages?.detractors}
          change={changes?.detractors}
          color={colours.ppForest}
        />
        <Box css={{ flex: "1" }} />
      </VStack>
      <Box css={{ position: "relative", width: "60%", height: "100%" }}>
        <Box
          css={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "50%",
            marginTop: "-90px",
            marginLeft: "10px",
          }}
        >
          <Text.H1
            css={{
              fontSize: "100px",
              color: colours.ppForest,
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontSize: "60px",
                paddingBottom: "20px",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              {npsPrefix}
            </span>
            {Math.abs(npsScore)}
            <Swatch.ScoreChange
              css={{
                fontSize: "20px",
                paddingBottom: "18px",
                display: "inline-block",
                verticalAlign: "bottom",
              }}
              change={data.npsScore.change}
            />
          </Text.H1>
        </Box>
        <ChartWrapper
          option={option}
          style={{
            marginTop: "-25px",
            marginLeft: "10px",
          }}
        />
      </Box>
    </HStack>
  );
};

const DonutLabel = ({ label, score, change, color }) => {
  return (
    <VStack
      css={{
        paddingBottom: "$2",
        borderBottom: `3px solid ${color}`,
        gap: "$1",
      }}
    >
      <Text.H4
        css={{
          fontSize: "12px",
          color: "$ppForest",
        }}
      >
        {label}
      </Text.H4>
      <HStack css={{ gap: "$1", alignItems: "end" }}>
        <Text.H1 css={{}}>{score.toFixed(0)}</Text.H1>
        <Text.H4 css={{ marginBottom: "6px" }}>%</Text.H4>
        <Swatch.ScoreChange change={change} />
      </HStack>
    </VStack>
  );
};

export default NPSSummary;
